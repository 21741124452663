
.errorForm {
  text-align: center;
  background: #252525;
  font-family: 'Montserrat', sans-serif;
  color: white;
  padding: 15px 20px;
  border-radius: 12px;
  @media (max-width: 390px) {
    width: 250px;
    padding: 10px 5px;
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }
  .btnAccess {
    padding: 12px 20px;
    border-radius: 8px;
    background: #b9191b;
    width: 70px;
    margin: 0 auto;
  }
  p {
    color: gray;
  }
}
textarea {
  resize: none;
}
form {
  background: rgb(26, 26, 26);
  width: 32%;
  margin: 50px auto;
  padding: 20px 10px ;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: white;
  min-width: 500px;

  @media (max-width: 520px) {
    min-width: 300px;
  }
  @media (max-height: 718px) {
    .form__wrapper {
      gap: 13px !important;
      .form__field {
        display: flex;
        justify-content: space-between;
      }
    }
    padding: 13px 7px !important;
    width: 27%;
    select, input, button, textarea {
      font-size: 13px !important;
      padding: 10px !important;
      color: white;
      border: none;
      border-radius: 8px;
      outline: none;
      margin: 0 !important;
    }

  }
  @media (max-height: 600px) {
    select, input, textarea {
      font-size: 12px !important;
      padding: 10px !important;
    }
  }

  font-family: 'Montserrat', sans-serif;
  select, input, textarea {
    font-size: 12px;
    padding: 13px;
    color: white;
    border: none;
    border-radius: 8px;
    outline: none;
  }
  input {
    display: block;
  }

  .form__wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto;
    .form__field {
      display: flex;
      justify-content: space-between;
    }
  }
  .buttonSubmit {
    background: #b9191b;
    cursor: pointer;
    margin-top: 40px;
    &:disabled {
      background: #793233;
    }
  }
  select, input, textarea {
    background: rgb(16, 16, 16);
    margin: 0;

  }

}
