.slide-in-left {
  -webkit-animation: slide-in-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}


.services__list_item {
  display: flex;
  gap: 25px;
  .list__item-opened {
    @media (max-width: 992px) {
     width: 70%;
    }
    border: 4px white solid;
    border-radius: 12px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    hr {
      margin: 0 auto;
      width: 70%;
      border: gray 2px solid;
    }
    span {

      padding-left: 8px;
      max-width: 340px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px;
      @media (max-width: 1095px) {
        font-size: 16px;
      }
      @media (max-width: 992px) {
       max-width: unset;
      }
      //@media (max-width: 562px) {
      //  font-size: 14px;
      //}
    }
    p {
      padding-left: 8px;
      padding-top: 10px;
      font-size: 14px;
    }
  }
  .handle__btn-services {
    width: 60px;
    height: 60px;
    cursor: pointer;
    user-select: none;
    @media (max-width: 1226px) {
      width: 40px;
      height: 40px;
    }
    @media (max-width: 992px) {
      width: 60px;
      height: 60px;
    }
    @media (max-width: 426px) {
      width: 45px;
      height: 45px;
    }

  }
  .list__p {
    max-width: 340px;
    font-size: 20px;
    margin: 5px 0;
    font-weight: 500;
    @media (max-width: 1226px) {
      font-size: 16px;
    }
    @media (max-width: 992px) {
      font-size: 20px;
      max-width: 600px;
    }
    @media (max-width: 426px) {
      font-size: 14px;
      max-width: unset;
    }


  }
}
