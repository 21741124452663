
.main__top {
  padding-top: 75px;
  font-family: Montserrat, sans-serif;
  background-size: cover;
  .header--mobile {
    position: relative;
    .header__menu {
      position: absolute;
      background: inherit;
      width: 300px;
      right: 5px;
      top: 60px;
      ul {
        margin: 0;
      }
      li {
        padding: 12px 20px;
      }
      a {
        color: #E5E5E5;
        font-size: 22px;
      }
      li:not(:last-child) {
        border-bottom: #B9191B 1px solid;
      }
    }
  }
  .header {
    background: rgba(35, 35, 35, 0.9);
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    @media (max-width: 768px) {
      padding: 10px;
    }
    .logo-mobile {
      width: 90px;
    }
    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 90%;
      .header__top-pres {
        display: flex;
        align-items: center;
        .logo {
          display: block;
          width: 120px;
        }
        p {
          margin: 0;
          color: #E52528;
          font-weight: bold;
          font-size: 22px;
          @media (max-width: 1096px) {
            font-size: 15px;
          }
        }
        span {
          font-weight: 600;
          color: #E5E5E5;
          @media (max-width: 1096px) {
            font-size: 12px;
          }
        }
      }
      .header__nav {
        display: flex;
        gap: 35px;
        @media (max-width: 1096px) {
          gap: 20px;
        }
        font-family: 'Ubuntu', sans-serif;
        li {
          font-size: 17px;
          @media (max-width: 1096px) {
            font-size: 14px;
          }
          a {
            color: #E5E5E5;
          }
        }
        #fortLink {
          a {
            color: #b9191b;
          }
        }
      }
      .header__call {
        background: #B9191B;
        color: #E5E5E5;
        font-weight: bold;
        padding: 10px 25px;
        border-radius: 25px;
        @media (max-width: 1096px) {
          padding: 7px 20px;
          font-size: 12px;
        }
      }
    }
  }
  .welcome {
    .welcome__text {
      text-align: center;
      color: #E5E5E5;
      p {
        font-size: 32px;
        @media (max-width: 576px) {
          font-size: 18px;
        }
      }
      h1 {
        font-size: 60px;
        @media (max-width: 576px) {
          font-size: 32px;
        }
        @media (max-width: 392px) {
          font-size: 23px;
        }
      }
    }
    .welcome__features {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      gap: 100px;
      flex-wrap: wrap;
      @media (max-width: 1096px) {
        gap: 5%;
      }
      @media (max-width: 533px) {
        gap: 15px;
        margin-top: 40px;
      }
      div {
        display: flex;
        align-items: center;
        gap: 20px;
        img {
          display: block;
          width: 80px;
          height: 80px;
          @media (max-width: 1096px) {
            width: 50px;
            height: 50px;
          }
          @media (max-width: 576px) {
            width: 40px;
            height: 40px;
          }
        }
        span {
          font-size: 19px;
          font-weight: bold;
          color: #E5E5E5;
          @media (max-width: 1096px) {
            font-size: 15px;
          }
        }
      }
    }
  }
}