.preloader__wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.preloader__body {
  height: 100%;
  display: flex;
  align-items: center;
}
.preloader {
  width:200px;
  height:200px;
  display:inline-block;
  padding:0px;
  border-radius:100%;
  border:6px solid;
  border-top-color: rgba(234, 5, 5, 0.89);
  border-bottom-color: rgba(68, 30, 30, 0.19);
  border-left-color: rgba(206, 21, 21, 0.65);
  border-right-color: rgba(61, 31, 31, 0.46);
  -webkit-animation: preloader4 0.8s linear infinite;
  animation: preloader4 0.8s linear infinite;
}
@keyframes preloader4 {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
@-webkit-keyframes preloader4 {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}
}