.footer {
  background: #252525;
  font-family: 'Montserrat', sans-serif;
  padding: 70px 0;
  margin-top: 100px;
  color: #E5E5E5;
  @media (max-width: 414px) {
    padding: 30px 0;
  }
  .footer__blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1336px)  {
      flex-direction: column;
    }
    .contacts {
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media (max-width: 1336px)  {
        flex-direction: row;
        gap: 7vw;
      }
      @media (max-width: 1174px)  {
       justify-content: space-between;
      }
      @media (max-width: 917px) {
        flex-direction: column;
        gap: 20px;
      }

      .contacts__item {
        display: flex;
        align-items: center;
        gap: 30px;
        @media (max-width: 917px) {
          align-items: center !important;
        }
        div {
          @media (max-width: 917px) {
            display: flex;
            align-items: center;
            gap: 15vw;
          }
        }
        @media (max-width: 1336px)  {
          align-items: unset;
          gap: 20px;
        }
        img {
          height: 45px;
          @media (max-width: 1174px)  {
            height: 40px;
          }
          @media (max-width: 917px) {
            height: 50px;
          }
          @media (max-width: 597px)  {
            height: 35px;
          }
          @media (max-width: 414px) {
            height: 30px;
          }
        }
        h3 {
          font-weight: 500;
          margin: 7px 0;
          font-size: 23px;
          @media (max-width: 1920px) {
            font-size: 1.3vw;
          }
          @media (max-width: 1336px)  {
            font-size: 21px;
          }
          @media (max-width: 1174px)  {
            font-size: 17px;
          }
          @media (max-width: 917px) {
           font-size: 21px;
          }
          @media (max-width: 597px)  {
            font-size: 17px;
          }
          @media (max-width: 414px) {
            font-size: 15px;
            max-width: 80px;
          }
        }
        a, p {
          color: #E5E5E5;
          max-width: 317px;
          @media (max-width: 1920px) {
            font-size: 0.95vw;
          }
          @media (max-width: 1336px)  {
            font-size: 18px;
            max-width: 300px;
          }
          @media (max-width: 1174px)  {
            font-size: 14px;
          }
          @media (max-width: 917px) {
            font-size: 16px;
          }
          @media (max-width: 597px)  {
            font-size: 14px;
          }
          @media (max-width: 414px) {
            font-size: 13px;
          }
        }
      }
      .contacts__item:not(:last-child) {
        a, p {

        }
      }
    }
    .geo {
      padding: 10px 0;
      display: flex;
      align-items: center;
      gap: 40px;

      @media (max-width: 1174px)  {
        img {
          display: none;
        }
        margin-top: 30px;
      }
      .geo__block {
        border-left: 1px solid #E52528;
        padding: 20px 60px;
        @media (max-width: 1174px)  {
          padding-top: 50px;
          border-left: none;
          border-top: 1px solid #E52528;;
        }
        @media (max-width: 414px) {
         padding: 20px 0;
        }
        iframe {
          display: block;
          @media(max-width: 1174px) {
            width: 70vw;
          }
          @media (max-width: 414px) {
            width: 95vw;
          }
        }
      }
    }
  }
}