.roulette {
  margin: 120px auto 0;
  border-radius: 15px;
  border: 5px solid #C52A2C;
  width: 720px;
  min-width: 230px;
  position: relative;
  display: flex;
  @media (max-width: 825px) {
    width: 100%;
    height: 250px;
  }
  @media(max-width: 630px) {
    height: 230px;
  }
  @media (max-width: 420px) {
    height: 165px !important;
  }
  @media (max-width: 375px) {
    height: 140px !important;
  }
  @media (max-width: 500px) {
    height: 210px;
  }
  height: 270px;
  #canvas {
    width: 100%;
    height: 75%;
  }
  .polygon__wrapper {
    display: flex;
    top: -1px;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
  .roulette_polygon {
    height: 45px;
    z-index: 0;
    top: -2px;
    @media(max-width: 630px) {
      height: 30px;
    }
    @media (max-width: 480px) {
      height: 20px;
    }
  }
  .roulette__txt_auth {
    text-align: center;
  }
  .roulette_title {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 32px;
    font-weight: 600;
    cursor: pointer;
  }
  .roulette__wrapper {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    .slider {
      margin: 50px auto;
      overflow-x: hidden;
      width: 70%;
      display: flex;
    }
    .slides {
      display: flex;
      width: 100%;
      gap: 20px;
      .slide {
        background: #252525;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 4s;
        border-radius: 20px;
        max-width: 354px;
        min-width: 100%;
      }

    }
  }
}