@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.57);
  top: 0;
  left: 0;
  z-index: 5;
}
.modalError {
  z-index: 6;
}
.formOrder {
  text-align: center;
  width: 300px;
  background: #b9191b;
  padding: 20px 30px;
  border-radius: 13px;
  font-size: 20px;
  margin: 50px auto;
  cursor: pointer;
}
.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
body {
  margin: 0;
  left: 0;
  top: 0;
  background: #0E0E0E;
}
ul {
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 25px;
}
.modalRoulette {
  position: fixed;
  display: flex;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.48);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .modalRoulette__body {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    .modalRoulette__content {
      font-family: 'Montserrat', sans-serif;
      flex-direction: column;
      width: 40%;
      @media(max-width: 746px) {
        width: 60%;
      }
      @media (max-width: 610px) {
        width: 85%;
      }
      @media (max-width: 500px) {
        height: 200px;
      }
      height: 300px;
      background: #181818;
      border: #b9191b 3px solid;
      border-radius: 12px;
      display: flex;
      align-items: center;
      color: #dedddd;
      text-align: center;
      justify-content: center;
      h1,h2 {
        width: 100%;
        margin: 0;
      }
      h1 {
        @media(max-width: 746px) {
          font-size: 24px;
        }
        @media (max-width: 430px) {
          font-size: 20px;
        }
      }
      h2 {
        @media (max-width: 746px) {
          font-size: 16px;
        }
      }
    }
  }
}
.rotate-scale-down-hor {
  -webkit-animation: rotate-scale-down-hor 0.45s linear both;
  animation: rotate-scale-down-hor 0.45s linear both;
}
@-webkit-keyframes rotate-scale-down-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
    transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateX(-180deg);
    transform: scale(0.5) rotateX(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-360deg);
    transform: scale(1) rotateX(-360deg);
  }
}
@keyframes rotate-scale-down-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
    transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateX(-180deg);
    transform: scale(0.5) rotateX(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-360deg);
    transform: scale(1) rotateX(-360deg);
  }
}
.main__profile {
  padding: 150px 0 !important;
  height: unset !important;
  button:disabled {
    background: #793233;
  }
}
.profile-content__phone {
  .phone__mask {

  }
  .phone-data__error {
    font-size: 12px;
    color: #C52A2C;
  }

  input {
    margin-left: 0 !important;
    padding: 5px;
  }
  p {
    font-size: 14px;
  }

}
/*HEADER*/
.main__top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("pictures/main_bg.png") no-repeat;
  height: 100vh;
  display: flex;
  padding: 100px 30px;
  justify-content: center;
  align-items: center;
  min-height: 590px;
  @media (max-width: 1096px) {
    height: 60vw;
  }
}
.main--wrapper {
  @media(max-width: 607px) {
    max-width: 100%;
    padding: 0;
  }
}

.main__services {
  background: #0E0E0E;
  font-family: Montserrat, sans-serif;
  padding: 100px 20px;
  color: #E5E5E5;

  @media (max-width: 607px) {
    padding: 40px 20px
  }
  .hoursTry {
    text-align: center;
    padding: 40px 0;
  }
  .services__info {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    gap: 10%;
    .services__img {
      width: 500px;
      height: 500px;
      @media (max-width: 1226px) {
        width: 400px;
        height: 400px;
      }
      @media (max-width: 1095px) {
        width: 300px;
        height: 300px;
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
    .services__list {
      display: flex;
      width: 500px;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 992px) {
        width: 95%;
      }
      @media (max-width: 607px) {
         width: 100%;
      }
    }

  }
  .services__main-list {
    padding-top: 175px;
    li {
      position: relative;
      z-index: 0;
      img {
        display: block;
      }
    }
    li:first-child {
      z-index: 5;
    }
    li:nth-child(2) {
      z-index: 4;
    }
    li:nth-child(3) {
      z-index: 3;
    }
    li:last-child {
      z-index: 1;
    }
    @media (max-width: 414px) {
      padding-top: 50px;
    }
    .main__list-title {
      h1 {
        font-weight: 600;
        text-align: center;
      }
      hr {
        width: 60%;
        border-color: #C52A2C;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      margin-top: 70px;
      gap: 30px;
      flex-wrap: wrap;
      @media (max-width: 1000px) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
      @media(max-width: 661px) {
        flex-direction: column;
      }

      li {
        transition: transform 0.15s;
        cursor: pointer;
        &:hover {
          transform: translateY(3px);
        }
      }
      img {
        width: 230px;
        @media(max-width: 661px) {
         margin: 0 auto;
        }
      }
    }
  }
}
.profile-content__edit {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(14, 14, 14, 0.85);
  z-index: 5;
  .profile-data__btn {
    margin-top: 10px;
  }
}
.btn__profile-link {
  height: 50px;
  z-index: 999;
  width: 50px;
  background: #B9191B;
  border-radius: 50%;
  color: white;
  position: fixed;
  bottom: 40px;
  transition: box-shadow .3s;
  box-shadow: 0 0 0 10px rgba(185, 25, 27, 0.3);
  &:hover {
    box-shadow: 0 0 0 10px rgba(185, 25, 27, 0.6);
  }
  right: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
  }
}
.profile-data__btn {
  background: #B9191B;
  color: #0e0e0e;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 30px;
  width: fit-content;
  padding: 10px 20px;
  a {
    font-size: 14px;
    color: inherit;
  }
}
.profile-content__blocks {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 60%;
  @media (max-width: 1000px){
    width: 80%;
  }
}
.profile-content__block {
  background: #1A1A1A;
  border-radius: 10px;
  padding: 40px 108px;
  h2 {
    @media (max-width: 430px){
      font-size: 20px;
    }
  }
  @media (max-width: 1000px){
    padding: 40px 70px;
  }
  @media (max-width: 430px){
    padding: 30px 40px;
  }
  color: white;
  .profile-content__data-col {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .profile-data__item {
      .p-f {
        display: flex;
        align-items: center;

        input, select {
          border: 1px solid #383838;
        }
        p {
          min-width: 70px;
        }
      }
      font-size: 20px;
      display: flex;
      justify-content: left;
      @media (max-width: 430px){
        font-size: 14px;
      }
      span, input, select{
        margin-left: 5px;
        color: #B9191B;
        font-size: 20px;
        max-width: 200px;
        background: transparent;
        border: none;
        @media (max-width: 430px){
          font-size: 14px;
        }
        outline: none;
      }
      p {
        margin: 0;
      }
    }
  }
}

.formOrderWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .formOrderCaption {
    font-size: 15px;
    line-height: 1.6;
    text-align: center;
    max-width: 700px;
    a {
      color: #C52A2C;
    }
  }
}

.main__news {
  background: rgba(0, 0, 0, 0.23);
  display: block;
  min-height: 100%;
  height: unset;
  padding: 100px 80px;
  .news__title {
    text-align: center;
    margin-top: 43px;
    margin-bottom: 70px;
    color: white;
    font-weight: 500;
  }
  .new__text {
    width: 600px;
    color: white;
    display: flex;
    flex-direction: column;
  }
  .new__text_block {
    margin: 50px 0;
    flex-direction: column;
    display: flex;
  }
  .new__description-opened {
    max-height: unset !important;
  }
  .new__description {
    max-height: 160px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .new__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 56px;
  }
  .new__open {
    color: #b9191b;
    display: flex;
    justify-content: flex-end;
  }
  .new__image {
    max-width: 580px;
    width: 580px;
    display: block;
  }
  .news__list {
    display: flex;
    flex-direction: column;
    gap: 150px;
  }
  .new__date {
    font-weight: 300;
    font-size: 26px;
    line-height: 32px;
    padding-top: 20px;
    color: #FF0003;
  }
  .new {
    display: flex;
    justify-content: space-between;
    max-height: 100%;
  }
  @media (max-width: 1413px) {
    .new__image {
      max-width: 480px;
      width: 480px;
    }
    .new__text {
      width: 450px;
    }
  }
  @media (max-width: 1134px) {
    .new__image {
      max-width: 380px;
      width: 430px;
    }
    .new__text {
      width: 440px;
    }
  }
  @media (max-width: 1020px) {
    .new__image {
      max-width: 100%;
      width: 100%;
    }
    .new__title {
      margin-top: 30px;
    }
    .new__text {
      width: 100%;
    }
    .new {
      flex-direction: column;
    }
  }

}
@media (max-width: 480px) {
  .main__news {
    padding: 100px 20px !important;
  }
}