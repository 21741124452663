.table-cargo {
  max-width: 76vw !important;
  width: 26vw;
  @media (max-width: 900px) {
    width: unset;
  }
  .table__body {
    border-bottom: 1px solid #b9191b;
  }
  .table__row-cargo {
    margin-top: 20px;
    display: flex;
    gap: 4%;
    .repair__camera {
      padding: 0 5px;
      border-right: #b9191b solid 1px;
      display: flex;
      flex-direction: column;
      width: 22%;
      gap: 10px;
      text-align: center;
      justify-content: center;
      span {
        width: 99%;
      }
      h6 {
        margin: 0;
      }
    }
    .repair__tires {
      width: 75%;
      .title {
        font-size: 11px;
        margin: 0;
        padding-bottom: 10px;
        border-bottom: #b9191b 1px solid;
      }
      .tires__body {
        display: flex;
        justify-content: space-between;
        h6 {
          font-size: 11px;
          margin: 0;
        }
        .tires__half {
          width: 44%;
          display: flex;
          margin-top: 10px;
          gap: 20px;
          h6 {
            font-size: 10px;
            @media (max-width: 800px) {
              font-size: 9px;
            }
          }
          flex-direction: column;
        }
        .tires__patch {
          display: flex;
          gap: 3%;
        }
        .tires__half:first-child {
          padding-right: 15px;
          border-right: #b9191b 1px solid;
        }
      }
    }
  }
  h5 {
    width: 5vw !important;
    max-width: 130px !important;
    font-size: 12px;
    @media(max-width: 1370px) {
      width: 6vw !important;
      font-size: 13px
    }
  }
  h4 {
    @media(max-width: 1370px) {
        padding: 0 2vw!important;
    }
  }
  @media (max-width: 926px) {
    min-width: 305px;
    h5 {
      width: 50px !important;
    }
    .table__body {
      gap: 15px!important;
    }
    h4 {
      padding: 0 15px!important;
    }
  }
}
.table-other {
  .descr-other {
    width: 100%;
    text-align: center;
  }
  .table__body {
    @media (max-width: 370px) {
      gap: 3.8vw !important;
    }
  }
  width: 33vw;
  left: -70% !important;
  max-width: 653px;
  @media (max-width: 1400px) {
    width: 29vw !important;
    h5 {
      font-size: 11px !important; ;
    }
  }
  .tb-sec-header {
    margin-top: 25px;
  }
  .tb-body-sec {
    padding-top: 20px !important;
    justify-content: space-around;
      .tb-sec-div {
        border-right: 1px solid #b9191b;
        @media (max-width: 420px) {
          width: 30% !important;
        }
      }
    .tb-cord {
      width: 55% !important;
      div {
        width: auto;
        display: unset;
      }
      h5 {
        text-align: unset;
        width: 90%;
      }
      .tb-cord-item {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-direction: row;
        @media (max-width: 420px) {
          max-width: 100% !important;
        }

      }
      .tb-cord-item:first-child {
        padding-bottom: 10px;
        border-bottom: 1px solid #b9191b;
      }

    }
    span {
      text-align: center;
    }
  }
  .tb-body-one {
    div {
      @media (max-width: 420px) {
        width: 26%!important;
      }
    }
  }
  .table__body {
    padding-top: 10px;
    div {
      width: 25%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h6 {
        margin: 0;
        @media (max-width: 420px) {
          font-size: 9px !important;
        }
      }
      h5 {
        text-align: center;
        margin-top: 10px;
      }
      span {
        width: unset;
      }
    }
  }
  .table__header {
    h6 {
      margin: 0 auto;
    }
    padding-bottom: 10px !important;
  }
  @media (max-width: 1400px) {
    left: -30% !important;
  }
  @media (max-width: 661px) {
    left: -10vw !important;
  }
}
.table-light_weight {
  @media (max-width: 1400px) {
    width: 45vw !important;
  }

  .table__header {
    margin-top: 30px;
    width: 100%;
    justify-content: space-around;
    h4 {
      @media (max-width: 800px) {
        font-size: 8px;
      }
    }
    span {
      @media (max-width: 420px) {
        font-size: 10px;
      }
    }
    @media (max-width: 800px) {
     justify-content: unset;
    }
  }
  .table__header-second {
    div {
      display: flex;
      flex-direction: column;
      max-width: 19% !important;
      @media (max-width: 804px) {
        max-width: 28% !important;
      }
      h5 {
        @media (max-width: 804px) {
          font-size: 8px !important;
        }
      }
      @media (max-width: 804px) {
        span {
          font-size: 8px;
        }
      }
    }
  }
  .table__body-second {
    justify-content: space-evenly;
    @media(max-width: 742px) {
      justify-content: space-around;
    }
    .table__body-el:first-child {
      width: 17%;
    }

  }
  .table__body-el {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    @media (max-width: 742px) {
      align-items: unset;
      justify-content: unset;
      width: 22%;
    }
    h4 {
      margin: 0 auto;
      text-align: center;
      @media(max-width: 742px) {
        margin: 0;
        text-align: unset;
      }
    }
    span {
      max-width: 100%;
      width: 100%;
    }
  }
  .table__body-el:not(:last-child) {
  }
  .table__body {
    h4 {
      width: 20%;
      padding: 0 3%;
      @media (max-width: 900px) {
        font-size: 13px;
      }
    }
  }
}
.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.table {

  border-radius: 15px;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.36);
  background: #0E0E0E;
  position: absolute;
  max-width: 75vw;
  left: -80%;
  color: white;
  padding: 20px 10px;
  padding-bottom: 40px;
  min-width: 285px;
  @media (max-width: 1400px) {
    width: 34vw;
    left: -30%;
  }
  @media(max-width: 661px) {
    left: unset;
  }
  @media (max-width: 413px) {
    left: -7vw !important;
  }
  /*UL должен быть релативом!!Й!!!!!!*/
  .table__header {
    display: flex;
    gap: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #C52A2C;
    div {
      max-width: 130px;
    }
  }
  h5 {
    color: white;
    font-weight: 600;
    width: 6vw;
    max-width: 113px;
    margin: 3px;
    @media(max-width: 1000px) {
      font-size: 11px;
      max-width: 90px;
    }
    @media(max-width: 661px) {
      font-size: 9px;
      max-width: 100px;
    }
  }
  span {
    font-weight: 300;
    font-size: 10px;
    width: 150px;
    @media(max-width: 1000px) {
      font-size: 9px;
      width: 110px;
    }
  }
  .table__body {
    display: flex;
    gap: 1.8vw;
    @media(max-width:780px) {
      .table__column:last-child {
        width: 50%;
        h4 {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    .table__column {
      h4 {
        padding: 0 1.5vw;
        font-size: 13px;
        max-width: 35px;
      }
    }
  }

}