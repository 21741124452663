.SuccessModal {
    width: 100vw;
    height: 100vh;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    justify-content: center;
    background: rgba(0, 0, 0, 0.151);
    position: fixed;
    z-index: 999;
    color: white;
    top: 0;
    left: 0;
    align-items: center;
    .sendedBlock {
        padding: 40px 50px;
        border: 2px solid red;
        border-radius: 35px;
        display: flex;
        background: #252525;
        flex-direction: column;
        align-items: center;
        @media (max-width: 726px) {
            .successTitle {
                font-size: 19px !important;
            }
            .successTitle img {
                width: 80px;
            }
            p {
                font-size: 14px !important;
            }
            .btn {
                padding: 10px 20px !important;
                font-size: 20px !important;
            }
        }
        @media (max-width: 474px) {
            .successTitle {
                font-size: 14px !important;
            }
            .successTitle img {
                width: 50px;
            }
            p {
                font-size: 12px !important;
            }
            .btn {
                padding: 10px 20px !important;
                font-size: 17px !important;
            }
            & {
                padding: 15px 30px;
            }
        }
        .successTitle {
            display: flex;
            gap: 40px;
            font-size: 25px;
        }
        p {
            text-align:center;
            font-size: 20px;
            color: #878787;
            margin-bottom: 30px;
        }
        .btn {
            padding: 10px 30px;
            text-align:center;
            border: none;
            color: #ffffff;
            border-radius: 35px;
            background: #b9191b;
            cursor: pointer;
            font-size: 25px;
        }
    }
}